import "./style.css";
import { useRef } from "react";
import MintBox from "../mintbox";
import Countdown from "react-countdown";
import Timer from "../timer";
import logo from "../../assets/images/n_logo.png";

function InlineVideoPlayer({ id, autoPlay }) {
  const ref = useRef();
  return (
    <div
      ref={ref}
      style={{ maxWidth: "600px", width: "600px", height: "auto" }}
      className={`uniqueId_Whistia_inline wistia_embed wistia_async_${id} viddeo videoFoam=true playerColor=#0055ff autoPlay=${autoPlay}`}
    ></div>
  );
}

function Cover() {
  return (
    <div className="main_container">
      <div className="cover_container">
        <Countdown
          date={new Date("26 August 2022 08:00 EDT")}
          renderer={({ days, hours, minutes, seconds, completed }) =>
            completed ? null : (
              <Timer d={days} h={hours} m={minutes} s={seconds} />
            )
          }
        />

        <h1>
          10,000 Amazingly Cute, Waffle-Loving Hamsters Need A Home - Get Yours
          Now And Play The Amazing Hamster Jamz Game For Free!
        </h1>
        <h4>
          Mint your 100% FREE NFT today and with it you'll unlock access to our
          fun, fast paced and retro-styled platformer game 'Hamster Jamz' where
          you play as the hero 'Waffles McFluffybutt' in a perilous quest for
          all things wafflicious
          <br /> <br /> Play anytime for free with your NFT - Just keep your NFT
          in your Crypto wallet (your NFT acts as your game pass for access) and
          make sure to your claim your position at the top of our{" "}
          <a
            href="https://leaderboard.hamsterjamz.com"
            rel="noreferrer"
            target="_blank"
          >
            LEADERBOARD
          </a>{" "}
          to win awesome merch and prizes! [more information along with contests news
          and game updates on{" "}
          <a
            href="https://twitter.com/fadedarcadenft"
            rel="noreferrer"
            target="_blank"
          >
            Twitter
          </a>
          ]
        </h4>

        <div>
          <MintBox />

          <a
            className="connect_btn"
            style={{
              display: "inline-block",
              textDecoration: "none",
              backgroundColor: "rgb(0, 85, 255)",
              padding: "18px 25px",
              margin: "0 5px 30px",
            }}
            href="https://start.hamsterjamz.com/"
            target="_blank"
            rel="noreferrer"
          >
            Play HamsterJamz
          </a>
        </div>

        <div className="youtube_">
          <InlineVideoPlayer id={"h78d9w9nef"} />
        </div>

        <h4>
          The Small Print: Hamsters love waffles, hate hungry wolves, spikey
          cactuses, marauding aliens and spooky ghosts. Fair mint, no
          whitelisting required, no minimum to mint, get your first NFT 100%
          free and invest in more for just 0.0001 ETH each.
        </h4>

        <div className="youtube_ nmb">
          <InlineVideoPlayer id={"d4ig3x3icv"} autoPlay={false} />
        </div>

        <p className="p_second">
          {" "}
          <span
            onClick={() =>
              window.open("https://start.hamsterjamz.com", "_blank")
            }
          >
            Enter Arcade
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open("https://twitter.com/fadedarcadenft", "_blank")
            }
          >
            Twitter HamsterJamz
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open("https://leaderboard.hamsterjamz.com", "_blank")
            }
          >
            Leaderboard
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open(
                "https://securedownloads.s3.amazonaws.com/TheHamsterJamzGameHistory.pdf",
                "_blank"
              )
            }
          >
            Whitepaper
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open("https://opensea.io/collection/hamsterjamz", "_blank")
            }
          >
            OpenSea
          </span>
        </p>

        <p className="powered_by">
          Powered by{" "}
          <a href="https://fadedarcade.com/" rel="noreferrer" target="_blank">
            <img src={logo} alt="FadedArcade" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default Cover;
