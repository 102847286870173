import React from "react";
import { ethers } from "ethers";
import { Alert, Mint } from "..";
import { AppConfig, config, Helper, _web3Modal, abi } from "../../../../config";
import { MdDoubleArrow } from "react-icons/md";

class Connect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      connected: false,
      sold: false,
      submit: false,
    };
  }

  async handleConnect() {
    const provider = await _web3Modal.connect();
    const { methods } = this.context;

    this.provider = new ethers.providers.Web3Provider(provider);
    const network_id = await this.provider.send("net_version");

    this.setState({ submit: true });

    // NETWORK ID
    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alerts: [
          {
            type: "error",
            message: `Change network to ${Helper.networkName(config.network)}.`,
          },
        ],
      });

      return false;
    }

    try {
      const signer = this.provider.getSigner();
      const address = await signer.getAddress();

      const contract = new ethers.Contract(
        config.contract_address,
        abi,
        signer
      );

      const paused = await contract.paused();
      const maxSupply = await contract.maxSupply();
      const totalSupply = await contract.totalSupply();
      const balanceOf = await contract.balanceOf(address);

      // PAUSED
      if (paused) {
        this.setState({
          alerts: [
            {
              type: "error",
              message: config.messages.paused_contract,
            },
          ],
          submit: false,
        });

        return false;
      }

      // SOLD
      if (maxSupply.eq(totalSupply)) {
        this.setState({
          alerts: [
            {
              type: "error",
              message: config.messages.sold_out,
            },
          ],
          sold: true,
          submit: false,
        });

        return false;
      }

      const cost = await contract.cost();
      const maxMintAmount = await contract.maxMintAmount();

      methods.updEthers({
        contract,
        signer,
        address,
        cost,
        maxMintAmount,
        balanceOf: balanceOf.toNumber()
      });

      this.setState({ connected: true, submit: false });
    } catch (err) {
      this.setState({
        alerts: [{ type: "error", message: err.message }],
        submit: false,
      });
    }
  }

  render() {
    const { alerts, connected, sold, submit } = this.state;

    // SOLD
    if (sold) {
      return (
        <div className="mint_container">
          <div className="message_box">
            <p className="alert-p">{config.messages.sold_out}</p>
          </div>
          <a
            className="connect_btn text-center text-decoration-none"
            href={config.messages.opensea_link}
            target="_blank"
            rel="noreferrer"
          >
            VISIT OPENSEA
          </a>
        </div>
      );
    }

    return (
      <>
        {alerts.length > 0 &&
          alerts.map((alert, index) => <Alert key={index} {...alert} />)}

        {!connected && alerts.length === 0 && (
          <button
            className="connect_btn"
            onClick={this.handleConnect.bind(this)}
            disabled={submit}
          >
            <span>
              <MdDoubleArrow />
            </span>
            {config.messages.mint_button_text}
          </button>
        )}

        {connected && alerts.length === 0 && <Mint />}
      </>
    );
  }
}

Connect.contextType = AppConfig;

export default Connect;
