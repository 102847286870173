import "./index.css";

const Timer = ({ d, h, m, s }) => {
  return (
    <div className="timer">
      <span className="timer_text">
        {d}
        <br /> DAYS
      </span>
      <span className="semi_colon">:</span>
      <span className="timer_text">
        {h} <br /> HOURS
      </span>
      <span className="semi_colon">:</span>
      <span className="timer_text">
        {m} <br /> MINUTES
      </span>
      <span className="semi_colon">:</span>
      <span className="timer_text">
        {s} <br /> SECONDS
      </span>
    </div>
  );
};

export default Timer;
